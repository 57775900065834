@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .box-border-1 {
    @apply border-[1px] border-solid;
  }
}

/* globals.css */
.text-gradient {
  @apply bg-text-gradient bg-clip-text text-transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tw-btn {
  @apply inline-flex px-4 py-[6px] border-solid border-[1.5px] border-[#919191] rounded-full justify-center items-center gap-2 bg-[#FFFFFF0D] backdrop-blur;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
